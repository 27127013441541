const Analytics = {
  handleLineCharts(event){
    $('.line-chart-link.active').removeClass('active');
  
    const link = $(event.currentTarget);
    $(link).addClass('active');
    let keys = $('#analytics-line-chart').data('keys'); 
    let dataset = $('#analytics-line-chart').data('val');
    
    let sectionData, chartColor;
    let multiLine = false;
    
    if($('.chart-handler a.active').length == 2){
      sectionData = dataset.total;
      multiLine = true;
    } else {
      let activeLink = $('.chart-handler a.active').first();
      
      if(activeLink.length) {
        if (activeLink.hasClass('sk')){
          sectionData = dataset.sk;
          chartColor = Charts.chartColors.red;
        } else if (activeLink.hasClass('total')){
          sectionData = dataset.total;
          chartColor = Charts.chartColors.blue;
        }
      } else {
        let empty_data = { count: 0, growth: 0, chart_data: {}};
        sectionData = {'users': empty_data, 'new_users': empty_data, 'sessions': empty_data, 'conversions': empty_data};
      }
    }
  
    // set all the counts and growths
    $(keys).each((i, key) => {
      let val = sectionData[key];
      let growthClass = 'up'
      if(parseFloat(val.growth) < 0){
        growthClass = 'down'
      }
      if(parseInt(val.growth) != 0){
        $(`.${key} .growth`).html(`
          <svg class="icon ${growthClass}" xmlns="http://www.w3.org/2000/svg"><use xlink:href="${arrowIconPath}"></use></svg>${val.growth}
        `);
      } else {
        $(`.${key} .growth`).html(val.growth);
      }
      $(`.${key} h2`).text(parseFloat(val.count) != 0 ? val.count : '-');
    });
  
    const cloned_element = $('#line-chart').clone();
    $('#line-chart').replaceWith(cloned_element);
    let linkKey = link.data('key');
    if(multiLine) {
      Charts.multiLineChart('line-chart', [dataset.sk[linkKey].chart_data, dataset.total[linkKey].chart_data]);
    } else {
      Charts.lineChart('line-chart', sectionData[linkKey].chart_data, chartColor);
    }
    
    $('.analytics-content_tabs_wrapper').removeClass().addClass(`analytics-content_tabs_wrapper active-tab-${linkKey}`);
  }
}

export default Analytics;
