import { Modal  } from "../packs/application";

const Dashboard = {
  showStaticModal(element_id, modal_class){
    Modal.close();

    const element = document.getElementById(element_id);
    const content = element.innerHTML;
    element.remove();

    if(modal_class){
      modal_class = `welcome-modal ${modal_class}`;
    } else {
      modal_class = 'welcome-modal';
    }

    Modal.showModal('', content, modal_class);
    setTimeout(function(){
      $('.accept-tos').on('click', function(){
        Modal.close();
      })
    }, 200);
  }
};

export default Dashboard;
