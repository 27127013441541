const RichTextEditor = {
  init() {
    if(tinymce !== undefined){
      const commonOptions = {
        content_css : "/tinymce/custom_content.css",
        theme_advanced_font_sizes: "10px,12px,13px,14px,16px,18px,20px",
        font_size_style_values : "10px,12px,13px,14px,16px,18px,20px",
      }
  
      $('.tinymce-textarea').each((i, editor) => {
        let textareaOptions = {
          selector: '#' + editor.id,
          plugins: 'link, lists',
          toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist',
        }
  
        tinymce.init({
          ...commonOptions,
          ...textareaOptions
        });
      });
  
      $('.tinymce-link-input').each((i, editor) => {
        let linkInputOptions = {
          selector: '#' + editor.id,
          plugins: 'link',
          toolbar: 'link',
          height: 180,
        }
  
        tinymce.init({
          ...commonOptions,
          ...linkInputOptions
        });
      });
    };
  }
}

export default RichTextEditor
