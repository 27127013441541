import RichTextEditor from "./rich_text_editor";

const Modal = {
  modalHeader(title){
    return `<div class="modal-header">
      <h4 class="modal-title">${title}</h4>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
        <svg class="icon" xmlns="http://www.w3.org/2000/svg"><use xlink:href="${modalCloseIcon}"></use></svg>
      </button>
    </div>`;
  },

  showRemoteModal(url, title, modal_class, modal_width){
    const header = '';
    title = title || '';
    modal_class = modal_class || '';
    Modal.close();
    const modal_content = $(`
      <div class="modal fade">
        <div class="modal-dialog ${modal_class}">
          <div class="modal-content">
            ${Modal.modalHeader(title)}
            <div class="modal-body all-categories clearfix"></div>
          </div>
        </div>
      </div>`
    );

    modal_content.modal('show');

    if (modal_width) {
      modal_content.find('.modal-dialog').css({ 'max-width': modal_width + 'px'});
    }

    modal_content.find('.modal-body').load(url, ()=>{
      modal_content.find('form').on('ajax:success', ()=>{
        Modal.close()
      });
    });

    modal_content
      .on('hidden.bs.modal', () => {
        tinymce. remove();
        modal_content.remove();
      }).on('shown.bs.modal', () => {
        $('.modal-body form').prepend("<div class='error-container'></div>");
        $('.modal-body .selectpicker').selectpicker({ size: 10 });
        RichTextEditor.init()
    });
  },

  showModal(title, content, modal_class){
    const modal_content = $(`
      <div class="modal fade">
        <div class="modal-dialog ${modal_class}">
          <div class="modal-content">
            ${title ? Modal.modalHeader(title) : ''}
            <div class="modal-body all-categories clearfix"></div>
          </div>
        </div>
      </div>`
    );

    modal_content.find('.modal-body').html(content);
    modal_content.modal('show');

    modal_content.on('hidden.bs.modal', () => {
      modal_content.remove();
    });
  },

  // open an iframe for the video
  showVideoModal(title, video_url){
    const modal_content = $(`
      <div class="modal fade">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">${Modal.modalHeader(title)}
            <div class="modal-body all-categories clearfix"></div>
          </div>
        </div>
      </div>`
    );

    modal_content.find('.modal-body').html(`<iframe width="100%" height="450" src=${video_url} frameborder="0" allowfullscreen></iframe>`);
    modal_content.modal('show');
    modal_content.on('hidden.bs.modal', () => {
      modal_content.remove();
    });
  },

  close() {
    $('.modal').modal('hide');
  }
};

export default Modal;
