if (typeof Chart != 'undefined'){ 
  Chart.register(ChartDataLabels);
  Chart.defaults.color = '#333';
}

window.Charts = {
  chartColors: {
    red: 'rgb(255, 99, 132)',
    blue: 'rgba(54, 162, 235, 0.9)',
    google: '#ff9933'
  },
  labelFontsize: 16,

  formattedValue(number){
    return Intl.NumberFormat('en', {notation: "compact"}).format(number);
  },

  lineChartOptions(labels, yMax, radius){
    let yOptions = {
      grid: {
        display: true
      },
      ticks: {
        precision: 0,
        callback: function(value, index, ticks){
          return Charts.formattedValue(value);
        }
      }
    }

    let month = document.getElementsByTagName('body')[0].dataset.month;

    let xOptions = {
      grid: {
        display: false
      },
      ticks: {
        maxRotation: 0,
        callback: function(value, index, ticks){
          let val = labels[index].split('/')[1];
          if(index == 0){
            return [val, month];
          } else {
            return val;
          }
        }
      }
    }

    if (yMax){
      yOptions.min = -3;
      yOptions.max = yMax;
    }

    // for 1/2 days, use radius 5, else 0

    let options = {
      maintainAspectRatio: false,
      elements: {
        point:{
          radius: radius
        }
      },
      scales: {
        x: xOptions,
        y: yOptions 
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      plugins: {
        tooltip: {
          enabled: true,
          usePointStyle: true,
          callbacks: {
            labelPointStyle: function(context) {
              return {
                  pointStyle: 'circle',
                  rotation: 0
              };
            },
            label: function(context){
              let activeLink = document.querySelector('.line-chart-link.active');
              
              if(!activeLink){
                return;
              }

              let linkParent = activeLink.parentElement;
              let label = context.dataset.label || '';

              if (label) {
                  label += ': ';
              }
              if (context.parsed.y !== null) {
                label = context.parsed.y;
                if(linkParent.classList.contains('CTR')){
                  return `${label.toLocaleString()}%`;
                }
                if(linkParent.classList.contains('CPC') || linkParent.classList.contains('Spend')){
                  return `$${label.toLocaleString()}`;
                }
              }
              return label.toLocaleString();
            }
          }
        },
        datalabels: {
          display: false
        }
      }
    }

    return options
  },

  // charts are made using chart js
  lineChart(element_id, json_data, chartColor, followers){
    Chart.defaults.plugins.legend = false;
    const ctx = document.getElementById(element_id).getContext('2d');
    const labels = Object.keys(json_data);
    const values = Object.values(json_data);
    let radius = Object.keys(values).length > 1 ? 0 : 5;

    let yMax;
    if(followers){
      yMax = Math.max(Math.max(...values), 3) + 1;
    }

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          data: values,
          borderColor: chartColor || this.chartColors.blue,
          borderWidth: 4,
          fill: false,
          tension: 0.5
        }]
      },
      options: this.lineChartOptions(labels, yMax, radius) 
    });
  },

  multiLineChart(element_id, dataset, chartColors, followers){
    if(!chartColors){
      chartColors = [this.chartColors.red, this.chartColors.blue]
    }
    Chart.defaults.plugins.legend = false;
    const ctx = document.getElementById(element_id).getContext('2d');
    const labels = Object.keys(dataset[0]);
    let yMax;
    if(followers){
      yMax = Math.max(
        Math.max(...Object.values(dataset[0])), 
        Math.max(...Object.values(dataset[1])),
        3
      ) + 1;
    }

    let radius = Object.keys(dataset[1]).length > 1 ? 0 : 5;

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            data: dataset[0], // instagram
            borderColor: chartColors[0],
            borderWidth: 4,
            yAxisID: 'y',
            fill: false,
            tension: 0.5
          },
          {
            data: dataset[1], // facebook
            borderColor: chartColors[1],
            borderWidth: 4,
            yAxisID: 'y',
            fill: false,
            tension: 0.5
          }
        ]
      },
      options: this.lineChartOptions(labels, yMax, radius)
    });
  },

  gaugeChart(element_id, json_data){
    let chartCtx = document.getElementById(element_id).getContext('2d');
    let values = json_data.values;
    let valueSum = values.reduce((partialSum, a) => partialSum + a, 0);
    let title;
    if(document.getElementById(element_id).dataset.title == 'reach'){
      title = `${valueSum > 0 ? Charts.formattedValue(valueSum) : '-'} Total Reach`
    } else {
      title = `${valueSum > 0 ? Charts.formattedValue(valueSum) : '-'} Total Audience Size`
    }

    if(valueSum == 0){
      values = [0, 100]
    }

    new Chart(chartCtx, {
      type: 'doughnut',
      data: {
          labels: json_data.labels,
          datasets: [{
              data: values,
              backgroundColor: json_data.background_colors,
              borderWidth: 0
          }]
      },
      options: {
          maintainAspectRatio: false,
          cutoutPercentage: 85,
          rotation: -90,
          circumference: 180,
          tooltips: {
              enabled: false
          },
          legend: {
              display: false
          },
          animation: {
              animateRotate: true,
              animateScale: false
          },
          plugins: { 
            title: {
              position: 'bottom',
              display: true,
              text: title,
              font: {
                size: Charts.labelFontsize,
                weight: 'bold',
                color: '#333'
              }
            },
            datalabels: {
              color: '#333',
              formatter: function(value, context) {
                if(value == 0){
                  return '';
                }
                return Charts.formattedValue(value);
              },
              font: {
                size: Charts.labelFontsize,
                weight: 'bold'
              }
            }
          }
      }
    });
  },

  pieChart(element_id, json_data){
    let chartCtx = document.getElementById(element_id).getContext('2d');
    let values = json_data.values;
    if(values.reduce((partialSum, a) => partialSum + a, 0) == 0){
      values = [100, 0, 0, 0]
    }
    const config = {
      type: 'pie',
      data: {
        labels: json_data.labels,
        datasets: [{
          data: values,
          backgroundColor: [
            '#FF9900',
            '#F6DA82',
            '#FADA7A',
            '#F5F0CD'
          ],
          borderColor: '#333',
          borderWidth:1,
          hoverOffset: 4
        }]
      },
      options: {
        responsive: false,
        plugins: {
          tooltips: {
            enabled: false
          },
          datalabels: {
            color: '#333',
            formatter: function(value, context) {
              if(value > 0){
                let label = json_data.labels[context.dataIndex];
                return `${label}\n${value}%`;
              } else {
                return null;
              }
            },
            font: {
              size: 14,
              weight: 'bold'
            }
          }
        }
      }
    };

    new Chart(chartCtx, config);
  }
}
