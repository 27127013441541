/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import 'bootstrap'
import 'bootstrap-select'
import 'jquery.nicescroll'
import 'daterangepicker'
import 'bootstrap-tagsinput'
import 'select2'

const Charts = require("../src/charts.js")

import "channels/report_notification_channel"
import Analytics from "../src/analytics"
import Dashboard from 'src/dashboard'
import FormSubmissions from 'src/form_submissions'
import Modal from 'src/modal'
import Paid from "../src/paid"
import Profile from 'src/profiles'
import Reports from 'src/reports'
import Social from "../src/social"
import trackingScripts from 'src/tracking_scripts'
import RichTextEditor from "../src/rich_text_editor"
import Utility from 'src/utility'

export { 
  Analytics, Charts, Dashboard, FormSubmissions, Modal, Paid, Profile, Reports, trackingScripts, 
  Social, RichTextEditor, Utility
}

import '../src/document_binders';

window.SK = window.SK || {};
window.SK.Reports = Reports;
window.SK.Utility = Utility;
window.SK.niceScrollOptions = {cursorcolor: "#ccc", width: '18px', cursorfixedheight: 36};
window.SK.nonAutohideNiceScrollOptions = {autohidemode: false, cursorcolor: "#ccc", width: '18px', cursorfixedheight: 36};

// css
import '../stylesheets/application.scss'

// images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
Turbolinks.start()
