import Rails from "@rails/ujs"
import Dropzone from "dropzone";
import { Reports, Modal, Utility, Analytics, Social, Paid, Profile, Dashboard, RichTextEditor  } from "../packs/application";

// handle left and right arrow click
$(document).on('click', '.sk-v2_left-panel li', function(){
  if (!$('.sk-v2_left-panel').hasClass('active')){
    $('.sk-v2_left-panel').addClass('active');
  }
});

$(document).on('change', '.properties-managed_list input', function(){
  let $this = $(this);

  if($(this).attr('id') == 'property_emails_toggle_all'){
    $('.properties-managed_list .property-checkboxes input').prop('checked', $this.is(':checked'));
  } else {
    if(!$this.is(':checked')){
      // if any tbody input unchecked checked, uncheck master
      $('#property_emails_toggle_all').prop('checked', false);
    } else {
      // if all tbody input checked, check master
      if($('.properties-managed_list .property-checkboxes input').length == $('.properties-managed_list .property-checkboxes input:checked').length){
        $('#property_emails_toggle_all').prop('checked', true);
      }
    }
  }

  Rails.fire($(this).parents('form')[0], 'submit');
});

$(document).on('change', '.management-company, .marketing-contact, .package-filter, #property_alert_instagram, #property_alert_google, #property_alert_facebook, #property_alert_pixel, #property_alert_other', function() {
  const companyValue = $('.management-company > button').attr('title') != "Nothing selected";
  const marketingContact = $('.marketing-contact > button').attr('title') != "Nothing selected";
  const packageFilter = $('.package-filter > button').attr('title') != "Nothing selected";
  const instagramCheck = $('#property_alert_instagram').prop("checked");
  const googleCheck = $('#property_alert_google').prop("checked");
  const facebookCheck = $('#property_alert_facebook').prop("checked");
  const otherCheck = $('#property_alert_other').prop("checked");
  const pixelCheck = $('#property_alert_pixel').prop("checked");
  if(companyValue || marketingContact || packageFilter || 
    instagramCheck || googleCheck || facebookCheck || otherCheck || pixelCheck) {
    $(".property-filter-card_submit").addClass('btn-primary')
  } else {
    $(".property-filter-card_submit").removeClass('btn-primary')
  }
});

$(document).on('click', '.property-filter-card > a', function() {
  $(".bs-deselect-all").trigger('click');
  $(".form-check-input").prop('checked', false);
  $(".property-filter-card_submit").removeClass('btn-primary')
});

$(document).on('click', '.filter-dashboard-btn', (event) => {
  $(event.currentTarget).toggleClass('active');
  $(".property-filter-card").toggleClass("show");
})

$(document).on('click', '.post-dropdown .dropdown-item', (event) => {
  event.preventDefault();
  $('.post-dropdown-handler').text(event.currentTarget.text);

  if($(event.currentTarget).hasClass('latest')){
    $('ul.latest-posts').removeClass('forced-hidden');
    $('ul.top-posts').addClass('forced-hidden');
  } else {
    $('ul.latest-posts').addClass('forced-hidden');
    $('ul.top-posts').removeClass('forced-hidden');
  }
});

$(document).on('click', '.keyword-dropdown .dropdown-item', (event) => {
  event.preventDefault();
  $('.keyword-dropdown-handler').text(event.currentTarget.text);

  if(event.currentTarget.dataset.target == 'top-google-keywords'){
    $('#keywords-csv-link, .top-google-keywords').removeClass('forced-hidden');
    $('#ads-csv-link, .top-google-ads').addClass('forced-hidden');
  } else {
    $('#keywords-csv-link, .top-google-keywords').addClass('forced-hidden');
    $('#ads-csv-link, .top-google-ads').removeClass('forced-hidden');
  }
});

$(document).on('click', '.ads-dropdown .dropdown-item', (event) => {
  event.preventDefault();
  $('.ads-dropdown-handler').text(event.currentTarget.text);
  $('.top-facebook-ads, .top-instagram-ads, .top-google-ads').addClass('forced-hidden');
  $(`.${$(event.currentTarget).data('target')}`).removeClass('forced-hidden');
});

// modals
$(document).on('click', 'a[data-remote-modal]', (event) => {
  event.preventDefault();
  let link = event.currentTarget;
  Modal.showRemoteModal(link.href, link.dataset.title, link.dataset.modalClass, link.dataset.modalWidth);
});

$(document).on('click', 'a[data-modal]', (event) => {
  event.preventDefault();
  Modal.showModal(event.currentTarget.title, $(`.${event.currentTarget.dataset.modalClass}`).html());
});

// profiles
$(document).on('click', '.alacarte-packages-scroll-handler', () => {
  Profile.handleScroll();
});

// properties
$(document).on('change', '#sk-left-panel_property-select', (event) => {
  Utility.posSearchIcon();
  window.location.href = $(event.currentTarget).find('option:selected').data('url');
});

$(document).on('click', '.property-cards .btn-light', (event) => {
  event.preventDefault();
  Modal.showModal('Submissions Closed', 'Submission is closed for the time being. Please wait until this is enabled again.');
});

$(document).on('change', '#ad_set_id', (event) => {
  var url = $(event.currentTarget).data('base-url').replace('ad_set_id', $(event.currentTarget).val());
  $.get(url, (html_data) => {
    $('#campaign-detail').html(html_data);
  });
});

$(document).on('click', '.asset-auditing_list_item_info', (event)=> {
  $(event.currentTarget).parents('li').toggleClass('detail-visible');
});

$(document).on('change', '.custom-file-filed_wrapper input', (event)=> {
  let fileName = event.currentTarget.value.replace(/^.*[\\\/]/, '');
  $(event.currentTarget).parent().find('.file-name').text(fileName);
});

$(document).on('click', '.report-toggler', (event) => {
  event.preventDefault();
  Reports.toggleContent(event.currentTarget);
});

$(document).on('click', '.report-save-toggler', (event) => {
  event.preventDefault();
  Reports.toggleSaveContent(event.currentTarget);
});

$(document).on('click', '.report-form .form-custom-radio', (event) => {
  event.preventDefault();
  let target = event.currentTarget;
  $(target.dataset.inputId).val(target.dataset.val);
  
  if(target.dataset.submit){
    $('.report-save').trigger('click');
  } else {
    $(target).parent().find('.form-custom-radio').removeClass('selected');
    $(target).addClass('selected');
  }
});

// aja sortable headers
$(document).on('click', '.ajax-sortable', (event) => {
  event.preventDefault();
  let url = event.currentTarget.href;
  let container = $(event.currentTarget).parents('.ajax-sortable-target');
  $.get(url, (html_data) => {
    container.html(html_data);
    setTimeout(function(){
      container.find('.nicescroll').niceScroll(window.SK.nonAutohideNiceScrollOptions);
    }, 0);
  })
});

// social page local packages charts
$(document).on('click', '.social-content .line-chart-link', (event) => {
  event.preventDefault();
  Social.handleLocalPackageCharts(event);
});

$(document).on('click', '.chart-handler a', (event) => {
  event.preventDefault();
  let clickedLink = $(event.currentTarget);
  
  // chart can not be blank
  if(clickedLink.hasClass('active') && $('.chart-handler a.active').length == 1){
    return false;
  }
  
  clickedLink.toggleClass('active');
  $('.line-chart-link.active').trigger('click');
});

// paid page charts
$(document).on('click', '.paid-content .line-chart-link', (event) => {
  event.preventDefault();
  Paid.handleLineCharts(event);
});

// paid chart handler
$(document).on('click', '.paid-chart-handler', (event) => {
  event.preventDefault();
  $(event.currentTarget).toggleClass('active');
  $('.line-chart-link.active').trigger('click');
  $($(event.currentTarget.dataset.toggle)).toggle();
});

$(document).on('click', '.analytics-content_tabs .line-chart-link', (event) => {
  event.preventDefault();
  Analytics.handleLineCharts(event);
});

$(document).on('click', '.announcement-dropdown', (event) => {
  event.preventDefault();
  return false;
});

// login page
$(document).on('click', '.form-group.password .icon', (event) => {
  let field = $(event.currentTarget).parent().find('input');
  if(field.attr('type') == 'password'){
    field.attr('type', 'text');
  } else {
    field.attr('type', 'password');
  }

  $(event.currentTarget).parent().toggleClass('visible');
});

// reports hide schedule option
$(document).on('click', '.schedule-option_content_title', (event) => {
  event.preventDefault()
  $(event.currentTarget).parents('.report-forms_card').removeClass('show-schedule-option');
  $(event.currentTarget).parents('.custom-report_card').removeClass('show-schedule-option');
});

$(document).on('turbolinks:load', () => {
  Utility.initNiceScroll();
  Utility.showCardProgress();
  Utility.highlightNav();
  Utility.checkBrokenPostImages();
  Utility.showFlashError();
  Utility.showFlashNotice();
  Utility.setImageHeights();
  Utility.initSelect2Fields();
  Utility.initAllDateRangePicker();
  Utility.initCharts();
  Utility.initTooltips();

  // header svg search position
  if (!$('.properties_index').length) {
    Utility.posSearchIcon();
  }
    
  // accept tos popup
  const acceptTosPopup = $('#accept-tos-popup');
  if (acceptTosPopup.length){
    Dashboard.showStaticModal('accept-tos-popup', 'accept-tos-popup modal-dialog-centered');
  }

  // properties live sync
  if($(document.body).data('live-sync')){
    const propertyId = $(document.body).data('property-id');
    Utility.showLoading({ property_id: propertyId });
  } else {
    Utility.hideLoading({ forced: true });
  }

  // error popup
  const errorPopupContent = $('#error-popup-content');
  if(errorPopupContent.length){
    Utility.showFlashError(errorPopupContent.text());
  }

  if(typeof(productionEnv) !== 'undefined'){
    //trackingScripts.execAll();
  }

  // init dropzones
  $('.dropzone').each((index, element) => {
    let attachment_ids_field = $('#content_request_attachment_ids');

    let dropzoneInstance = new Dropzone(`div#${element.id}`, { 
      dictDefaultMessage: "Drag & drop a file to attach it, or <br/><a style='color: #007bff;'>browse for a file...<a>",
      url: element.dataset.url,
      maxFiles: element.dataset.maxfiles,
      method: element.dataset.method || 'post',
      success: function (file, response) {
        if(response.status == 422) {
          var elemId = element.id.split('-')[1]
          $(`.error-${elemId}`).html(response.message);
          return;
        }
        
        if(response.user_id) {
          if($('span.user-avatar').length){
            $('span.user-avatar').replaceWith(`<img src="${response.image_url}" class="user-avatar"/>`);
          } else {
            $(".user-avatar").attr('src', response.image_url);
          }
          dropzoneInstance.removeAllFiles(true);
          return;
        }

        let attachment_ids = attachment_ids_field.val();
        if(attachment_ids){
          attachment_ids = attachment_ids.concat(`,${response.id}`); 
        } else {
          attachment_ids = response.id;
        }
        attachment_ids_field.val(attachment_ids);
      }
    });
  });

  // reports page
  $('.report-forms_card .standard-report-item_info').css({
    height: ($('#report-form-card-prospect-generator .standard-report-item_info').height())
  });

  // trigger page load top meta meta ads
  if($('#meta-ads-container').length){
    let url = $('#meta-ads-container').data('url');
    $.get(url, (html_data) => {
      $('#meta-ads-container').html(html_data);
      window.SK.Utility.initNiceScroll();
    });
  }
  RichTextEditor.init();
});
