const Profile = {
  handleScroll(){
    const scroll_content = $('#alacarte-packages .property-cards');
    Profile.scroll_width = Profile.scroll_width || $('.alacarte-packages-content')[0].scrollWidth;
    Profile.window_width = Profile.window_width || $('.alacarte-packages-content')[0].offsetWidth;
    Profile.initial_margin = Profile.initial_margin || Math.abs(parseInt(scroll_content.css('margin-left')));
    Profile.shifts = Profile.shifts || [];

    let card_width = $('#alacarte-packages .card').first().width() * 3; // 2 cards at a time
    let shift_size = Profile.scroll_width - (Profile.window_width + Math.abs(parseInt(scroll_content.css('margin-left'))));

    if(!Profile.scroll_reverse){
      let margin_left = (Math.min(card_width, shift_size) + Profile.initial_margin);
      Profile.shifts.push(margin_left);

      scroll_content.animate({
        marginLeft: ('-=' + margin_left + 'px')
      }, 500, () => {
        shift_size = Profile.scroll_width - (Profile.window_width + Math.abs(parseInt(scroll_content.css('margin-left'))));

        if (shift_size <= 0){
          Profile.scroll_reverse = true;
          Profile.shifts = Profile.shifts.reverse();
          $('.alacarte-packages-scroll-handler').addClass('prev');
        }
      });
    } else {
      scroll_content.animate({
        marginLeft: ('+=' + Profile.shifts.pop() + 'px')
      }, 500, () => {
        if (Profile.shifts.length == 0){
          Profile.scroll_reverse = false;
          $('.alacarte-packages-scroll-handler').removeClass('prev');
        }
      });
    }
  }
}

export default Profile;
