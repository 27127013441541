const Social = {
  handleLocalPackageCharts(event){
    $('.line-chart-link.active').removeClass('active');
  
    const link = $(event.currentTarget);
    $(link).addClass('active');
    let keys = $('#local-package').data('keys'); 
    let dataset = $('#local-package').data('val');
    
    let sectionData, chartColor;
    let multiLine = false;
    $('.engagement-rate-total, .engagement-rate-fb, .engagement-rate-insta').removeClass('visible');

    if($('.chart-handler a.active').length == 2){
      sectionData = dataset.combined;
      multiLine = true;
      $('.engagement-rate-total').addClass('visible');
    } else {
      let activeLink = $('.chart-handler a.active').first();
      
      if(activeLink.length) {
        if (activeLink.hasClass('insta')){
          sectionData = dataset.insta;
          chartColor = Charts.chartColors.red;
          $('.engagement-rate-insta').addClass('visible');
        } else if (activeLink.hasClass('fb')){
          sectionData = dataset.fb;
          chartColor = Charts.chartColors.blue;
          $('.engagement-rate-fb').addClass('visible');
        }
      } else {
        let empty_data = { count: 0, growth: 0, chart_data: {}};
        sectionData = {'impressions': empty_data, 'engagement': empty_data, 'reach': empty_data, 'followers': empty_data};
      }
    }
    
    // set all the counts and growths
    $(keys).each((i, key) => {
      let val = sectionData[key];
      let growthClass = 'up'
      
      if(val.growth){
        if(parseFloat(val.growth) < 0){
          growthClass = 'down'
        }
        if(parseInt(val.growth) != 0){
          $(`.${key} .growth`).html(`
            <svg class="icon ${growthClass}" xmlns="http://www.w3.org/2000/svg"><use xlink:href="${arrowIconPath}"></use></svg>${val.growth}
          `);
        } else {
          $(`.${key} .growth`).html(val.growth);
        }
      }
      
      $(`.${key} h2`).text(parseFloat(val.count) != 0 ? val.count : '-');
    });
  
    const cloned_element = $('#line-chart').clone();
    $('#line-chart').replaceWith(cloned_element);
    let linkKey = link.data('key');
    let followers = link.data('followers');
    if(multiLine) {
      Charts.multiLineChart('line-chart', [dataset.insta[linkKey].chart_data, dataset.fb[linkKey].chart_data], null, followers);
    } else {
      Charts.lineChart('line-chart', sectionData[linkKey].chart_data, chartColor, followers);
    }
    $('.social-content_tabs_wrapper').removeClass().addClass(`social-content_tabs_wrapper active-tab-${linkKey}`);
  }
}

export default Social;
