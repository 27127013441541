const Reports = {
  toggleContent(target) {
    if(target.dataset.reportType){
      $('#report_report_type').val(target.dataset.reportType);
    }
    $(target.dataset.target).toggleClass(target.dataset.class);
  },

  toggleSaveContent(target) {
    $(target.dataset.target).toggleClass(target.dataset.class);
  }
}

export default Reports;
