import consumer from "./consumer";

consumer.subscriptions.create({ channel: "ReportNotificationChannel" }, {
   // Called when the subscription is ready for use on the server.
   connected: function(message) {
    console.log("connected Notification channel")
  },
  disconnected: function(message) {
    console.log("disconnected")
  },
  received: function (data) {
    if(parseInt(document.getElementById('channel-identifier').dataset.id) != data.user_id){
      return;
    }

    if (data.download_url) {
      let grabLink = document.getElementsByClassName(`report-${data.report_id}`)[0];
      if(grabLink){
        grabLink.setAttribute('href', `/reports/${data.report_id}/download`);
        grabLink.removeAttribute('data-remote');
        grabLink.classList.remove('generating');
        grabLink.classList.remove('in-progress');
      }
      
      // always download the report
      const link = document.createElement("a");
      link.href = `/reports/${data.report_id}/download`;
      link.download = "";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("No download URL found in the received data");
    }
  }
})
