const Paid = {
  handleLineCharts(event){
    let keys = $('#paid-ads-charts').data('keys'); 
    let dataset = $('#paid-ads-charts').data('val');

    $('.line-chart-link.active').removeClass('active');
  
    const link = $(event.currentTarget);
    $(link).addClass('active');
    // replace the meta and google csv download links
    // $('.paid-nav-links_item.meta').attr('href', $('.paid-nav-links_item.meta').data('url').replace('metric', link.data('key')));
    // $('.paid-nav-links_item.google').attr('href', $('.paid-nav-links_item.google').data('url').replace('metric', link.data('key')));

    let sectionData, chartColor;
    let multiLine = false;
    
    if($('.paid-chart-handler.active').length == 2){
      sectionData = dataset.combined;
      multiLine = true;
    } else {
      let activeLink = $('.paid-chart-handler.active').first();
      
      if(activeLink.length) {
        if (activeLink.hasClass('meta')){
          sectionData = dataset.meta;
          chartColor = Charts.chartColors.blue;
        } else if (activeLink.hasClass('google')){
          sectionData = dataset.google;
          chartColor = Charts.chartColors.google;
        }
      } else {
        let empty_data = { count: 0, growth: 0, chart_data: {}};
        sectionData = {}
        $.each(keys, (i, key) => {
          sectionData[key] = empty_data
        });
      }
    }

    // set all the counts and growths
    $(keys).each((i, key) => {
      let val = sectionData[key];
      let growthClass = 'up'
      if(parseFloat(val.growth) < 0){
        growthClass = 'down'
      }
      if(parseInt(val.growth) != 0){
        $(`.${key} .growth`).html(`
          <svg class="icon ${growthClass}" xmlns="http://www.w3.org/2000/svg"><use xlink:href="${arrowIconPath}"></use></svg>${val.growth}
        `);
      } else {
        $(`.${key} .growth`).html(val.growth);
      }
      
      let count = val.count;
      if(parseFloat(count) == 0 || count == '$0' || count == '0%'){
        count = '-';
      }
      $(`.${key} h2`).text(count);
    });
  
    const cloned_element = $('#line-chart').clone();
    $('#line-chart').replaceWith(cloned_element);
    $('.industry-avg').html('')

    let linkKey = link.data('key');
    if(multiLine) {
      let chartColors = [Charts.chartColors.blue, Charts.chartColors.google];
      Charts.multiLineChart('line-chart', [dataset.meta[linkKey].chart_data, dataset.google[linkKey].chart_data], chartColors);
      let industryAvg = dataset.meta[linkKey].industry_avg; 
      if(industryAvg){
        $('.industry-avg').html(`${industryAvg.label} <strong>${industryAvg.value}</strong>`);
      }
    } else {
      Charts.lineChart('line-chart', sectionData[linkKey].chart_data, chartColor);
      let industryAvg = sectionData[linkKey].industry_avg; 
      if(industryAvg){
        $('.industry-avg').html(`${industryAvg.label} <strong>${industryAvg.value}</strong>`);
      }
    }
    $('.paid-content_tabs_wrapper').removeClass().addClass(`paid-content_tabs_wrapper active-tab-${linkKey}`);
  }
}

export default Paid;
